.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.h-100 {
    height: 100vh;
}

.reset-card {
    background-color: #1a1a1a;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 20px;
}

.reset-card .reset-card-body {
    padding: 2rem;
    flex-direction: column;
    display: flex;
}

.reset-card .reset-card-title {
    font-family: 'RussoOne', 'sans-serif';
    color: #FFCE00;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.reset-card .reset-card-text {
    font-size: 1rem;
}

.reset-card .btn-primary {
    color: white;
    font-family: 'RussoOne', sans-serif;
    background-color: #4E1496;
    border-color: #792bd9;
    border-style: solid;
    border-width: 3px;
    border-top-color: #9f5df0;
    -webkit-appearance: none;
    font-size: 1.2rem;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    text-decoration: none;
    text-decoration-color: #4E1496;
    transition: ease-in-out 0.3s;
    width: 50%;
    max-width: 50%;
    min-width: 100px;
    align-self: center;
}

.reset-card .btn-primary:hover {
    background-color: #2B174C;
    cursor: pointer;
}

.reset-card .reset-card-subtitle {
    color: #929292;
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin-bottom: 2rem;
}

.reset-card-input-container {
    display: flex;
    flex-direction: column;
}
.password-input {
    border-radius: 10px;
    border-color: grey;
    font-family: "Russo One";
    background-color: rgba(0,0,0,0.5);
    border-width: 2px;
    padding: 10px;
    margin-bottom: 15px;
    color: white;
    width: 100%;
}
.password-input:focus {
    outline: none;
}

.error-text {
    color:red;
    font-size: 15px;
    margin-bottom: 10px;
}