.title {
    color: white;
    font-family: RussoOne;
    font-size: 30px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.subheading {
    color: white;
    font-family: RussoOne;
    font-size: 25px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.text-container {
    align-self: center;
    height: 100%;
    left: 0;
    width: 60%;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
}

.text {
    color: rgb(230, 230, 230);
    font-family: Caecilla, sans-serif;
    overflow-wrap: break-word;
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: justify;
    width: 70%;
    margin-top: 30px;
}


@media only screen and (max-width: 1000px) {
    .text-container {
        width: 100%;
    }
    .text {
        width: 80%;
        font-size: 15px;
        line-height: 20px;
    }
}
