.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.h-100 {
    height: 100vh;
}

.success-card {
    background-color: #1a1a1a;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
}

.success-card .success-card-body {
    padding: 2rem;
}

.success-card .success-card-title {
    font-family: 'RussoOne', 'sans-serif';
    color: #FFCE00;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.success-card .success-card-text {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.success-card .btn-primary {
    color: black;
    font-family: 'RussoOne', sans-serif;
    background-color: #ffce00;
    border-color: #2d0451;
    font-size: 1.2rem;
    padding: 0.75rem 2rem;
    border-radius: 5px;
    text-decoration: none;
    text-decoration-color: #ffce00;
    transition: ease-in-out 0.3s;
}

.success-card .btn-primary:hover {
    background-color: #b99400;
    cursor: pointer;
}

.success-card .success-card-subtitle {
    color: #929292;
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin-bottom: 2rem;
}