.term-title {
    color: rgb(60,60,60);
    font-family: RussoOne;
    font-size: 30px;
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.term-subheading {
    color: rgb(60,60,60);
    font-family: RussoOne;
    font-size: 25px;
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.term-container {
    align-self: center;
    align-items: flex-start;
    padding-left: 50px;
    padding-right: 80px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: white
}

.terms {
    color:  rgb(60,60,60);
    font-family: "Roboto Light", sans-serif;
    overflow-wrap: break-word;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 10px;
}